import React from 'react'
import { Flex } from '@rebass/grid'
import { BreakpointProvider } from 'react-socks'

import Footer from '../components/footer'
import Head from '../components/head'
import HeaderPrivacy from '../components/privacy/header'
import Navbar from '../components/Navbar'
import PrinciplesPrivacy from '../components/privacy/principles'

const PAGE_TITLE = 'Hubtype - Privacy Policy';

const Privacy = props => {
  return (
    <BreakpointProvider>
      <Head
        alternates={props.pageContext.i18n}
        autopilot={props.pageContext.autopilot}
        lang={props.pageContext.lang}
        title={PAGE_TITLE}
        uri={props.pageContext.relativeUrl}
      />
      <Flex flexDirection="column" css={{ overflow: 'hidden' }}>
        <Navbar
          theme="dark"
          style={{
            background: 'linear-gradient(180deg, #333333 0%, #6500CB 1266.15%)',
            borderBottom: 'none',
          }}
          {...props}
        />
        <HeaderPrivacy {...props} />
        <Flex flexDirection="column" style={{ background: 'white' }}>
          <PrinciplesPrivacy {...props} />
          <Footer style={{ paddingTop: 0 }} {...props} />
        </Flex>
      </Flex>
    </BreakpointProvider>
  )
}

export default Privacy
