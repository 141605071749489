import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import { WEBSITE_DOMAIN } from '../constants/app'

class Head extends React.PureComponent {
  static propTypes = {
    alternates: PropTypes.array.isRequired,
    autopilot: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ]).isRequired,
    description: PropTypes.string,
    lang: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }

  render() {
    const { alternates, autopilot, description, lang, title, uri } = this.props

    return (
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `${autopilot}`,
          },
        ]}
      >
        <html lang={lang.replace('_', '-')} />
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        <meta name="application-name" content="hubtype" />
        <meta name="apple-mobile-web-app-title" content="hubtype" />
        {
          alternates && 
            alternates.map(i18n => !i18n.deprecated && (
            <link
              rel="alternate"
              hreflang={i18n.locale.replace('_', '-')}
              href={`${WEBSITE_DOMAIN}${i18n.path}${i18n.uri || uri}`}
              key={i18n.locale}
            />)
        )}
      </Helmet>
    );
  }
}

export default Head
