import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Section from '../../components/Section'
import Line from '../../static/img/home/greyLine.svg'

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 45px;
  color: #ffffff;
  margin-bottom: -38px;
`
const Paragraph = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */

  color: #333333;
`

const Description = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
`

const StyledLine = styled(Line)``

const HeaderPrivacy = props => {
  const { bgHeader } = useStaticQuery(
    graphql`
      query {
        bgHeader: file(relativePath: { eq: "home/bg_header_privacy.png" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  let bg = (
    <Img
      fluid={bgHeader.childImageSharp.fluid}
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1,
      }}
    />
  )

  return (
    <>
      <Section py={120} background={bg}>
        <Flex mt={3} ml={[3, 0]}>
          <Title>Privacy Policy</Title>
        </Flex>
      </Section>
      <Section>
        <Flex
          ml={[3, 0]}
          mr={[3, 0]}
          flexDirection="column"
          width={['80%', '65%']}
          mt={5}
        >
          <Description>
            HUBTYPE is committed to protecting and respecting your privacy. In
            this privacy policy ("Privacy Policy"), we explain how HUBTYPE
            collects, uses, discloses and protects the Personal Information you
            submit to us, including, for example, when accessing and using
            HUBTYPE websites or applying for job offerings.
          </Description>
          <Description>
            Our Privacy Policy has been drafted to comply with applicable data
            privacy laws, in particular, the EU General Data Protection
            Regulation (“GDPR”). If the GDPR does not apply to you, not all
            terms of this Privacy Policy may be relevant to you.
          </Description>
          <Box>
            <Paragraph>As used in this Privacy Policy,</Paragraph>
          </Box>
        </Flex>
        <Flex ml={[3, 0]} mr={[3, 0]} width={['80%', '50%']}>
          <Box mr={3}>
            <Paragraph>1.</Paragraph>
          </Box>
          <Paragraph>
            <b>“HUBTYPE”, "we," "our" and "us" means METIS SOLUTIONS, S.L.</b>{' '}
            and, when applicable, its affiliates.
          </Paragraph>
        </Flex>
        <Flex mt={3} ml={[3, 0]} width={['80%', '50%']}>
          <Box mr={3}>
            <Paragraph>2.</Paragraph>
          </Box>
          <Paragraph>
            <b>“Controller”</b> for the purposes of the GDPR, other data
            protection laws applicable in member states of the European Union
            and other provisions related to data protection is{' '}
            <b>METIS SOLUTIONS, S.L.</b>, C/ Roc Boronat 117, 2ª planta. 08018
            Barcelona.
          </Paragraph>
        </Flex>
        <Flex mt={3} ml={[3, 0]} width={['80%', '50%']}>
          <Box mr={3}>
            <Paragraph>3.</Paragraph>
          </Box>
          <Paragraph>
            <b>“Personal Information”</b> generally has the same meaning as
            personal data or personal identifiable information (PII). Personal
            Information is defined in the data privacy laws applicable in your
            country. It includes any information relating to an identified or
            identifiable natural person. This means any individual who can be
            identified directly or indirectly by reference to an identifier such
            as name, identification number, location data, online identifiers
            (for example, IP addresses – if they can be used to identify you) or
            to one or more factors specific to the physical, physiological,
            genetic, mental, economic, cultural or social identity of that
            natural person. Put simply, this includes data which either by
            itself or with other data held by us or available to us, can be used
            to identify you.
          </Paragraph>
        </Flex>
        <Flex ml={[5, 0]} width={['70%', 1]}>
          <StyledLine />
        </Flex>
      </Section>
    </>
  )
}

export default HeaderPrivacy
