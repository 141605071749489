import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { D, M } from '../breakpoints'
import Section from '../../components/Section'

const Paragraph = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */

  color: #333333;
`
const Principle = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 1px;
  color: #c4c4c4;
`
const Subtitle = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px; /* identical to box height */

  color: #333333;
`

const PrinciplesPrivacy = props => {
  return (
    <Section>
      <Flex ml={[3, 0]} flexDirection="column">
        <Box mt={5}>
          <Paragraph>
            HUBTYPE is committed to the following key principles:
          </Paragraph>
        </Box>
        <Box width={['90%', 1]}>
          <Principle> PRINCIPLE 1</Principle>
          <Subtitle>
            We limit how, and with whom, we share your personal information.
          </Subtitle>
        </Box>
        <Box width={['85%', 1]}>
          <Paragraph>
            HUBTYPE will only share your Personal Information with others for
            the following purposes
          </Paragraph>
        </Box>
        <Flex flexDirection="column" width={['90%', '65%']}>
          <Flex mt={3}>
            <Box mr={3}>
              <Paragraph>a)</Paragraph>
            </Box>
            <Paragraph>at your request;</Paragraph>
          </Flex>
          <Flex mt={3}>
            <Box mr={3}>
              <Paragraph>b)</Paragraph>
            </Box>
            <Paragraph>
              to process or service a transaction or product authorized or
              requested by you; this may include sharing, when applicable,
              within the HUBTYPE group of entities;
            </Paragraph>
          </Flex>
          <Flex mt={3}>
            <Box mr={3}>
              <Paragraph>c)</Paragraph>
            </Box>
            <Paragraph>
              when required by law to disclose such information to appropriate
              authorities;
            </Paragraph>
          </Flex>
          <Flex mt={3}>
            <Box mr={3}>
              <Paragraph>d)</Paragraph>
            </Box>
            <Paragraph>
              to companies that assist us in marketing, recruiting, placement
              and servicing our products and services; for example, in order to
              support our information technology or to handle mailings on our
              behalf; and
            </Paragraph>
          </Flex>
          <Flex mt={3}>
            <Box mr={3}>
              <Paragraph>e)</Paragraph>
            </Box>
            <Paragraph>to our professional advisers.</Paragraph>
          </Flex>
        </Flex>
        <Box>
          <Principle> PRINCIPLE 2</Principle>
          <Subtitle>Purpose and legal basis of the processing.</Subtitle>
        </Box>
      </Flex>
      <D>
        <Flex witdh={1}>
          <Box mr={3} width={1 / 3}>
            <Paragraph>
              {' '}
              <b>Personal Information</b>
            </Paragraph>
          </Box>
          <Box width={1 / 3}>
            <Paragraph>
              <b>Purpose</b>
            </Paragraph>
          </Box>
          <Box width={1 / 3}>
            <Paragraph>
              <b>Legal basis</b>
            </Paragraph>
          </Box>
        </Flex>
      </D>
      <Flex
        width={[1, '80%']}
        backgroundColor="#F2F2F2"
        justifyContent="flex-start"
      >
        <Flex flexDirection={['column', 'row']} ml={3}>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Personal Information</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              Information we receive from you when (i) you visit www.hubtype.com
              and other HUBTYPE websites and (ii) any other information you
              subsequently provide to us orally, in writing or through the
              internet (i.e. using the chatbots): this may include your full
              name, postal address, e-mail address, employer/business and
              professional information, job titles, telephone and fax numbers,
              demographic information, IP address.
            </Paragraph>
          </Box>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Purpose</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              The Personal Information is used in providing the website and
              answering requests from you (e.g. if you choose to register to
              receive information about our products and services or if you
              enquire about these, we will use your Personal Information in
              order to respond).
            </Paragraph>
          </Box>
          <Box mt={3} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Legal basis</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              The processing is necessary for the performance of a contract to
              which you are party or in order to take steps at the request of
              you prior to entering into a contract. Processing is necessary for
              the purposes of our legitimate interests, i.e. providing a website
              for information and use.
            </Paragraph>
          </Box>
        </Flex>
      </Flex>
      <Flex width={[1, '80%']} justifyContent="flex-start">
        <Flex flexDirection={['column', 'row']} ml={3}>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Personal Information</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              Name, address, e-mail address and other information about your
              transactions and communications with us.
            </Paragraph>
          </Box>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Purpose</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              The Personal Information is used to process or service a
              transaction or product authorized or requested by you.
            </Paragraph>
          </Box>
          <Box mt={3} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Legal basis</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              The processing is necessary for the performance of a contract to
              which you are party or in order to take steps at the request of
              you prior to entering into a contract.
            </Paragraph>
          </Box>
        </Flex>
      </Flex>
      <Flex
        width={[1, '80%']}
        backgroundColor="#F2F2F2"
        justifyContent="flex-start"
      >
        <Flex flexDirection={['column', 'row']} ml={3}>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Personal Information</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              Name, address, e-mail address and other contact information.
            </Paragraph>
          </Box>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Purpose</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              To periodically contact you to inform you of new products and/or
              services we provide or that we consider to be of interest to you.
            </Paragraph>
          </Box>
          <Box mt={3} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Legal basis</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              The processing is necessary for the performance of a contract to
              which you are party or in order to take steps at the request of
              you prior to entering into a contract. Processing is necessary for
              the purposes of our legitimate interests, i.e. providing a website
              for information and use.
            </Paragraph>
          </Box>
        </Flex>
      </Flex>
      <Flex width={[1, '80%']} justifyContent="flex-start">
        <Flex flexDirection={['column', 'row']} ml={3}>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Personal Information</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              IP address and other information submitted by your browser.
            </Paragraph>
          </Box>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Purpose</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              To diagnose any problems with our server and administer our
              website.
            </Paragraph>
          </Box>
          <Box mt={3} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Legal basis</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              Processing is necessary for the purposes of our legitimate
              interests, i.e. providing a website for information and use
            </Paragraph>
          </Box>
        </Flex>
      </Flex>
      <Flex
        width={[1, '80%']}
        backgroundColor="#F2F2F2"
        justifyContent="flex-start"
      >
        <Flex flexDirection={['column', 'row']} ml={3}>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Personal Information</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              Application data, including your name, address, CV, birthdate.
            </Paragraph>
          </Box>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Purpose</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              Processing is required to enable us to administer the recruiting
              process, including the set-up of an electronic job applicant HR
              file, managing your application, organizing interviews. We may
              retain your Personal Information following your unsuccessful
              application so that we may contact you in case of future job
              vacancies. Your data may also be shared with other HUBTYPE group
              companies to consider your application for other job openings
            </Paragraph>
          </Box>
          <Box mt={3} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Legal basis</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              Primarily, the processing is necessary to take steps for entering
              into a contract with you. We also have a legitimate interest to
              (i) store your data for a period of up to 2 years following the
              conclusion of an unsuccessful application and/or (ii) to share it
              with the HUBTYPE group of entities and/or (iii) to retain and use
              your data as far as necessary for the establishment, exercise or
              defense of legal claims.
            </Paragraph>
          </Box>
        </Flex>
      </Flex>
      <Flex width={[1, '80%']} justifyContent="flex-start">
        <Flex flexDirection={['column', 'row']} ml={3}>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Personal Information</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>All of the above.</Paragraph>
          </Box>
          <Box mt={3} mr={[0, 3]} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Purpose</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              For compliance with legal and regulatory requirements and
              corporate governance obligations.
            </Paragraph>
          </Box>
          <Box mt={3} width={['85%', 1 / 3]}>
            <M>
              <Box>
                <Paragraph>
                  {' '}
                  <b>Legal basis</b>
                </Paragraph>
              </Box>
            </M>
            <Paragraph>
              Processing is necessary for compliance with legal obligations to
              which we are subject.
            </Paragraph>
          </Box>
        </Flex>
      </Flex>
      <Box mt={4} ml={[3, 0]} width={['90%', '65%']}>
        <Paragraph>
          Where you wish to provide us with Personal Information about another
          person, including colleagues or the persons you act on behalf of, you
          must ensure that you have their prior permission to do this. You must
          also share with them a copy of this Privacy Policy as well as any
          other relevant privacy statements (see above) before you ask them for
          this permission.
        </Paragraph>
      </Box>
      <Box mt={3} ml={[3, 0]} width={['90%', '65%']}>
        <Paragraph>
          We collect Personal Information for the purposes described above when
          you visit{' '}
          <a href="https://www.hubtype.com" style={{ color: '#FF2B5E' }}>
            www.hubtype.com
          </a>{' '}
          and other HUBTYPE websites and from public sources, such as recruiting
          and business portals (i.e.
          <a href="https://www.linkedin.com" style={{ color: '#FF2B5E' }}>
            {' '}
            www.linkedin.com
          </a>
          ).
        </Paragraph>
      </Box>
      <Box ml={[3, 0]} width={['80%', '65%']}>
        <Box mt={5}>
          <Principle> PRINCIPLE 3</Principle>
          <Subtitle>
            We establish safeguards to help ensure the security and
            confidentiality of your information.
          </Subtitle>
        </Box>
        <Box>
          <Paragraph>
            HUBTYPE restricts access to your company’s information to our
            employees who need it to do their job. Employees with access to your
            information are required to strictly maintain the confidentiality of
            such information.
          </Paragraph>
        </Box>
        <Box>
          <Paragraph>
            HUBTYPE maintains physical, electronic and procedural safeguards
            that comply with Industry standards to protect your company’s
            information. We routinely test our information systems and websites
            to help ensure that unauthorized access does not occur.
          </Paragraph>
        </Box>
        <Box mt={5}>
          <Principle> PRINCIPLE 4</Principle>
          <Subtitle>
            We keep your personal information for as long as it is necessary to
            do so to fulfil the purposes for which it was collected as described
            above.
          </Subtitle>
        </Box>
        <Box>
          <Paragraph>
            The criteria we use to determine data retention periods for Personal
            Information includes the following: (i){' '}
            <b>Retention in case of queries:</b> We will retain it for a
            reasonable period after the relationship between us has ceased in
            case of queries from you; (ii)
            <b>Retention in case of claims:</b> We will retain it for the period
            in which you might legally bring claims against us; (iii){' '}
            <b>
              Retention in accordance with legal and regulatory requirements:
            </b>{' '}
            We will consider whether we need to retain it after the period
            described in (ii) because of a legal or regulatory requirement, e.g.
            to comply with tax or fiscal duties; (iv){' '}
            <b>Retention in case of job applications:</b> If you applied for a
            job offering with HUBTYPE and have not been successful, your
            application data will be retained in our talent pool for a limited
            period as defined in Principle 2.
          </Paragraph>
        </Box>
        <Box mt={6}>
          <Subtitle>Give Us Your Feedback</Subtitle>
        </Box>
        <Box>
          <Paragraph>
            Our goal is to protect your privacy. To comment or help us improve,
            please contact us via email (hi@HUBTYPE.com) or telephone (+34) 634
            50 57 76. You may also contact us via written letter at HUBTYPE’s
            address listed above. We may ask you to provide a copy of your proof
            of identity.
          </Paragraph>
          <Paragraph>
            If you consider that we are in breach of our obligations under data
            protection laws, you may lodge a complaint with the competent Data
            Protection Authority, which may be the supervisory authority in your
            country of residence, place of work or of an alleged infringement of
            data protection laws.
          </Paragraph>
        </Box>
        <Box mt={5}>
          <Subtitle>Changes to this Privacy Policy</Subtitle>
        </Box>
        <Box>
          <Paragraph>
            This privacy policy may be modified from time to time to comply with
            applicable laws or to conform to our current business practices. We
            will post any changes to this on our website and will endeavor to
            notify you via your contact email. When notifying such changes to
            you, we will also explain what the likely impact of those changes on
            you will be, if any. We encourage you to revisit the Privacy
            Statement that is posted on our web site from time to time to check
            for updates.
          </Paragraph>
        </Box>
        <Box mt={5}>
          <Subtitle>Additional information we want you to know:</Subtitle>
        </Box>
        <Box>
          <Paragraph>
            <b>Transfers outside the EU/EEA.</b> We also transfer the Personal
            Information we process to countries outside the European Economic
            Area (<b>”EEA”</b>) (e.g., when one of our service providers or
            equipment is based outside the EEA, such as for hosting your
            Personal Information). We have put in place adequate safeguards with
            respect to the protection of your privacy, fundamental rights and
            freedoms, and the exercise of your rights, e.g. we establish an
            adequate level of data protection, usually through EU Standard
            Contractual Clauses based on the EU commission’s model clauses.
          </Paragraph>
        </Box>
        <Box>
          <Paragraph>
            <b>
              Provision of Personal Information / Automated decision making.
            </b>
            Please note that the Personal Information we collect from you is
            necessary to providing the services and the website to you. Failure
            to provide such data may not enable us to provide our services to
            you or make our website accessible. We do not use automatic
            decision-making or profiling of individuals.
          </Paragraph>
        </Box>
        <Box>
          <Paragraph>
            <b>Your Rights.</b> You have various rights under data privacy laws
            in your country. These may include (as relevant): the right to
            request access to the Personal Information we hold about you; the
            right to rectification including to require us to correct inaccurate
            Personal Information; the right to request restriction of processing
            concerning you or to object to processing of your Personal
            Information, the right to request the erasure of your Personal
            Information where it is no longer necessary for us to retain it; the
            right to data portability including to obtain Personal Information
            in a commonly used machine readable format in certain circumstances
            such as where our processing of it is based on a consent; the right
            object to automated decision making including profiling (if any)
            that has a legal or significant effect on you as an individual; and
            the right to withdraw your consent to any processing for which you
            have previously given that consent.
          </Paragraph>
        </Box>
        <Box>
          <Paragraph>
            <b>Marketing Information.</b> With your consent, where relevant, we
            will keep your name, address and contact details (including
            telephone numbers and email addresses) in our databases and may from
            time to time use that information to make you aware of our related
            products and services as well as updates on developments in our
            industry sector generally which may be of interest to you. We may
            contact you in writing, by telephone or email for this. If at any
            time you decide that you do not want your contact details used for
            these purposes, you may object or revoke your consent for receiving
            marketing communications by following the instructions in the
            relevant marketing communication (e.g., clicking on the
            “Unsubscribe” button) or by contacting us (see “Give Us Your
            Feedback” above).
          </Paragraph>
        </Box>
        <Box>
          <Paragraph>
            <b>Security Statement.</b> We take reasonable precautions to protect
            your information. In particular, we implemented appropriate
            technical and organizational measures designed to ensure a level of
            security appropriate to the risk, including as appropriate: (a)
            pseudonymisation (such as where data is separated from direct
            identifiers so that linkage to an identity is not possible without
            additional information that is held separately) and encryption, (b)
            protecting the ongoing confidentiality, integrity, availability and
            resilience of systems and services used to process your Personal
            Information, (c) providing the ability to restore the availability
            and access to Personal Information in a timely manner in the event
            of a physical or technical incident; and (d) maintaining a process
            for regularly testing, assessing and evaluating the effectiveness of
            technical and organizational security measures. When you submit
            information to us through our website, your information is protected
            both online and offline. All data transferred to/from the HUBTYPE
            internal network, from/to an external entity, is encrypted to
            industry standards. Please keep in mind that messages you send to us
            by Internet e-mail may not be secure. We maintain appropriate
            physical, electronic and procedural safeguards to ensure the
            security, integrity and privacy of your personal information within
            our company. Only those employees who may require your information
            to perform a specific job are granted access to your organization’s
            identifiable information. Furthermore, all employees are kept
            up-to-date on our security and privacy practices.
          </Paragraph>
        </Box>
        <Box>
          <Paragraph>
            <b>Our Use of Cookies and Analytic Tools.</b> HUBTYPE uses “cookies”
            and analytic tools as further described in our Cookie Policy.
          </Paragraph>
        </Box>
        <Box mb={6}>
          <Paragraph>
            <b>Third Party Websites.</b> Our websites and mobile apps may
            contain links to third-party websites. If you follow these links,
            you will exit our websites or mobile apps. This privacy policy does
            not apply to websites of third parties. HUBTYPE cannot accept
            liability for the use of your Personal Information by these third
            parties. Your use of these websites is at your own risk. For more
            information on how these third parties treat your Personal
            Information, please check their privacy policy (if available).
          </Paragraph>
        </Box>
      </Box>
    </Section>
  )
}

export default PrinciplesPrivacy
